<template>
    <div class="up_content_inner up_dashboard">
        <div class="up_main_container_sm">
            <div class="up_dashboard_menu_list">
                <ul>
                    <li>
                        <router-link to="/customer/add-website" >
                            <figure><img :src="imageUrlFrontEnd + 'dist/img/addnewsite-icon.png'" alt="Add New Site"></figure>
                            <h4>Add New Site</h4>
                            <p>Put website name and url to get widget</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/customer/website-listing">
                            <figure><img :src="imageUrlFrontEnd + 'dist/img/mexsisting-icon.png'" alt="Manage Existing Sites"></figure>
                            <h4>Manage Existing Sites</h4>
                            <p>Adjust creatives, monitor performance, and manage budgets</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/customer/branding-listing">
                            <figure><img :src="imageUrlFrontEnd + 'dist/img/managebranding-icon.png'" alt="Manage Branding"></figure>
                            <h4>Manage Branding</h4>
                            <p>Add, edit, and delete Branding</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/customer/support-videos">
                            <figure><img :src="imageUrlFrontEnd + 'dist/img/supportvedio-icon.png'" alt="Support"></figure>
                            <h4>Support</h4>
                            <p>Access documentation and resources for adding advertisers and activating campaigns</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/customer/additional-services">
                            <figure><img :src="imageUrlFrontEnd + 'dist/img/additional-service.png'" alt="Support"></figure>
                            <h4>Additional Services</h4>
                            <p>Put website name and url to get widget</p>
                        </router-link>
                    </li>
                </ul>
            </div>
            <!-- Billing Details Modal -->
            <BillingDetailsModal class="add_card_dashboard_billing" :visible="billingModal" />
            <!-- Billing Details Modal -->
        </div>
    </div>
</template>

<script>
import BillingDetailsModal from '../popups/BillingDetails.vue';
import { imageUrlFrontEnd } from '../../../config/constant';
import { useStore } from 'vuex';
import { computed, onBeforeMount, ref } from 'vue';

export default {
    name: 'Dashboard',
    components: {
        BillingDetailsModal
    },
    setup(){
        let store = useStore();

        let billingModal = ref(false);

        const userCardStatus = computed(() => store.getters.customerDetails);

        onBeforeMount(() =>{
            billingModal.value = userCardStatus.value.userCard === false ? true : false;
        });

        return{
            imageUrlFrontEnd,
            billingModal
        }
    }
}
</script>

<style lang="scss">
@import '../../../assets/style/component/userportal/pages/Dashboard';
</style>